@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  /* font-family: "Inter", sans-serif !important; */
  font-family: 'Open Sans', sans-serif !important;
  margin: 0 !important;
}

.container-1200px {
  width: 1200px !important;
}
.go2072408551 {
  padding: 10px !important;
}
.go685806154 {
  margin-right: 8px !important;
  font-size: 10px !important;
}
